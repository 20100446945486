import React from "react";
import './LogoSlider.css'
import Slider from "react-slick";
import logo1 from '../img/logo1.png';
import logo2 from '../img/logo2.png';
import logo3 from '../img/logo3.png';
import logo4 from '../img/logo4.jpg';
import logo5 from '../img/logo5.png';
import logo6 from '../img/logo6.png';
import logo7 from '../img/logo7.png';
import logo8 from '../img/logo8.png';
import logo9 from '../img/logo9.png';
import logo10 from '../img/logo10.png';
import logo11 from '../img/logo11.png';



const LogoSlider = () => {
  const logos = [
    logo1,
    logo2,
    logo3,
    logo4,
    logo5,
    logo6,
    logo7,
    logo8,
    logo9,
    logo10,
    logo11
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="logo-slider">
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} className="logo-slide">
            <img src={logo} alt={`Company Logo ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LogoSlider;
