import ScrollToTop from "./components/ScrollToTop";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavigationBar  from './components/Navbar';
import Home from './components/Home';
import './App.css';
import Curriculum from './components/Curriculum';
import Project from './components/Project';
import { Feature } from './components/Feature';
import Jobprep from './components/Jobprep';
import LogoSlider from './components/LogoSlider';
import Highlight from './components/Highlight';
import FAQ from './components/Faq';
import Expert from './components/Experts';
import Review from './components/Review';
import Foot from './components/Footer';
import Privacy from './components/Privacy';
import Faq from "./components/FaqAll";
import PopupForm from "./components/PopupForm";
import Eligible from "./components/Eligibility";



function App() {
  return (
    <Router>
    <div className="App">
      <PopupForm/>
    <ScrollToTop />
    <NavigationBar/>
    <Routes>
          <Route
            path="/"
            element={
              <>
      
      <Home/>
      <Highlight/>      
      <Eligible/>
      <Curriculum/>
      <Project/>
      <Jobprep/>
      <LogoSlider/>
      <Feature/>
      <Expert/>
      <Review/>
      <FAQ/>
      
      </>
            }
          />
      <Route path="/faq" element={<Faq />} />
     <Route path="/privacy" element={<Privacy />} />
     </Routes>
     <Foot/>
    </div>
    </Router>
  );
}

export default App;
