import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Hi1 from '../img/Communication.webp';
import Hi2 from '../img/Curriculum.webp';
import Hi3 from '../img/Mock_Interview.webp';
import Hi4 from '../img/p2.png';
import Hi5 from '../img/LIve_Doubts.webp';
import Hi6 from '../img/Labs.webp';
import Hi7 from '../img/Placement_Assistance.webp';
import Hi8 from '../img/EMI.webp';

const highlights = [
    { img: Hi2, text: "250+ Hours of Live Online Classes" },
    { img: Hi1, text: "100+ Hours Self paced Material" },
    { img: Hi4, text: "Dedicated Doubt Sessions" },
    { img: Hi5, text: "Mentorship Sessions With Industry Experts" },
    { img: Hi3, text: "100% Job Support" },
    { img: Hi6, text: "100% Course Fee Refund On Completion" },
    { img: Hi7, text: "Industry related assignments, case study and capstone projects"},
    { img: Hi8, text: "Fresher - INR 3.50-7.50 LPA Experienced - 50-200% Hike" }
];

const Highlight = () => {
    return (
        <section className="py-5">
            <Container>
                <Row className="mb-4">
                    <Col>
                        <h1 style={{ fontWeight: '900', fontFamily: 'margaine sans-serif',textAlign: 'center' }}>
                            Program <span style={{ color: 'rgb(231 121 55)' }}>Highlights</span>
                        </h1>
                    </Col>
                </Row>
                <Row>
                    {highlights.map((highlight, index) => (
                        <Col md={3} xs={12} className="mb-4" key={index}>
                            <Card className="h-100 d-flex flex-row align-items-center" style={{ background: 'aliceblue' }}>
                                <div className="p-2">
                                    <img
                                        src={highlight.img}
                                        className="img-fluid"
                                        style={{ maxHeight: '90px' }}
                                        alt="highlight-img"
                                    />
                                </div>
                                <Card.Body className="d-flex align-items-center">
                                    <Card.Text className="m-0 text-center">
                                        {highlight.text}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default Highlight;
