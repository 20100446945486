import React, { useState, useRef } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import emailjs from '@emailjs/browser';


const serviceId = "service_o0bp90e";
const templateId = "template_kvpqfec";

const CurriculumModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({ name: "", phone: "", email: "" });
  const [status, setStatus] = useState({});
  const formRef = useRef();

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    

    try {
      const emailResult = await emailjs.sendForm(serviceId, templateId, formRef.current, 'VWsEdichg5c76MiDG');
      console.log("Email sent successfully:", emailResult.text);
      setStatus({ success: true, message: 'Message sent successfully' });

      downloadPDF();
      handleClose();
    } catch (error) {
      console.error("Error sending email:", error);
      setStatus({ success: false, message: 'Failed to send message' });
    }
  };

  const downloadPDF = () => {
    try {
      const pdfUrl = '/DATA SCIENCE 360 - Brochure.pdf';
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", "Curriculum-Brochure.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} style={{ marginTop: "65px" }}>
        <Modal.Header closeButton style={{ background: "#0d6efd" }}>
          <Modal.Title style={{ fontFamily: 'Margarine sans-serif', fontWeight: '400', fontStyle: 'normal',color: '#fff' }}>
            Fill the Form to Download the Brochure 
            <p style={{ fontSize: '15px' }}>Get 100% Fee Refund on Completion</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label style={{ color: "#121212" }}>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group controlId="phone">
              <Form.Label style={{ color: "#121212" }}>Phone</Form.Label>
              <Form.Control
                type="tel"
                name="phone"
                
                placeholder="Phone No. (e.g., +91-1234567890)"
                value={formData.phone}
                onChange={handleChange}
                required
                pattern="^\+?[0-9]{10,15}$" 
        title="Please enter a valid phone number with only numbers and an optional '+' (e.g., +1234567890)."
              />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label style={{ color: "#121212" }}>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              style={{ marginTop: "10px" }}
            >
              Submit & Download
            </Button>
          </Form>
          {status.message && (
            <p style={{ marginTop: '10px', color: status.success ? 'green' : 'red' }}>{status.message}</p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CurriculumModal;