import React from 'react';
import {Container, Row, Col } from 'react-bootstrap';
import member1 from '../img/sir2.png.webp';
import member2 from '../img/rohan.jpg_11zon.webp';
import member3 from '../img/Ratnesh.jpg.webp';
import member4 from '../img/Arpit.jpg_11zon.webp';
import member5 from '../img/Shweta.jpg.webp';
import member6 from '../img/shaurab-768x768.jpg.webp';
import member7 from '../img/Sangeeta_Nahar.jpg';
import member8 from '../img/Dr._Meghna.jpg';
import './Expert.css';


const Expert = () => {
  const teamMembers = [
    { img: member1, name: 'Nitin Sharma', title: 'Data Analyst', description: 'As the backbone of Brainalyst, Nitin Sharma’s visionary leadership and unwavering passion for data science education create a transformative learning experience for all.' },
    { img: member2, name: 'Rohan Saini', title: 'Data Scientist', description: 'Rohan’s profound knowledge and forward-thinking approach in data science empower our learners to harness the full potential of their data.' },
    { img: member3, name: 'Ratnesh Kumar', title: 'Data Scientist|Problem Solver', description: 'Ratnesh’s knack for solving complex data problems with creative solutions ensures our students gain actionable insights and hands-on expertise.' },
    { img: member4, name: 'Arpit Goel', title: 'Associate Director', description: ' Arpit’s mastery in big data and advanced analytics equips our students with the skills needed to tackle the most challenging data science problems.' },
    {img: member5,name:'Sweeta Agarwal',title:'Data Engineer',description:" Sweta's deep technical expertise and practical insights from her role at Walmart provide our students with a robust understanding of data engineering"},
    {img: member6,name:'Saurabh Kulkarni',title:'Associate Director',description:'Saurabh’s strategic acumen and leadership experience at UBS inspire our students to excel and innovate in the ever-evolving field of data science.'},  
    {img: member7,name: 'Sangeeta Nahar',title: 'Senior Data Analyst',description: "Sangeeta's acedmics and industry experience encourage our learners to think logically and applying analytics in an efficient way."},
    {img: member8,name: 'Dr. Meghna',title: 'Data Analyst',description: "Dr. Meghna's deep research and journey as an Prof. (Statistics) helps our students to learn the complex statistical terms with ease and fun."} 
  ];

  return (
    <section className="our-team" id="team" style={{marginTop: '3rem'}}>
      
      <Container>
        <div className="section-title"><h2 style={{fontFamily: 'Margarine, sans-serif', fontStyle: 'normal', fontWeight: '900',}}>Meet Our Industry <span style={{color: 'rgb(231 121 55)',fontWeight: '700'}}>Experts & Mentors</span></h2><p style={{fontSize: '1rem'}}>Discover the driving force behind Brainalyst’s success. </p></div><hr />
        
        <Row>
          {teamMembers.map((member, index) => (
            <Col md={3} sm={6} xs={12} key={index} className="team-member">
              <div className="member-image" style={{ backgroundImage: `url(${member.img})` }}></div>
              <div className="member-info">
                <h3>{member.name}</h3>
                <p className="title">{member.title}</p>
                <p>{member.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
export default Expert;