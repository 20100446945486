import React, { useState } from "react";


const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <button onClick={toggleOpen} style={styles.question}>
        {question}
      </button>
      {isOpen && <div style={styles.answer}>{answer}</div>}
    </div>
  );
};

 const FAQ = () => {
 
    "A hybrid program means that learners will have access to both self-paced classes and live online interactive sessions. If you prefer to complete the course faster, you can focus on the self-paced modules and still have the opportunity to attend live sessions for additional support and interaction.";
  const faqData = [
    
         
    {
      question: "What is the Data Science 360 Program?",
      answer:
        "The Data Science 360 Program covers everything you need to know about Data Analytics, Data Visualization, Data Science, and Machine Learning. It’s for people who want to start or change their careers to roles like Data Analysts, Data Scientists, Business Analysts, or ML Engineers.",
    },
    {
      question: "What is the Fee Refund Policy ?",
      answer:
        'With the Fee Refund Program, every enrolled learner is eligible for 100% Refund on course fee upon completion of the program. The Refund is guaranteed and comes with a "No-Questions Asked" Policy. You will also get the "100% Course Fee Refund" clearly mentioned in the fee receipt.',
    },    
    {
      question: "Who can take this Data Science course?",
      answer:
        "Our Data Science course is for students, graduates, and working professionals from any background. Whether you’re new to this or already know something about data, our course is made for you.",
    },
    {
      question:
        "What skills will I learn with this Data Science online course?",
      answer:
        "With our Data Science online course, you’ll learn programming in SQL, R and Python, using tools like Excel, PowerBi, and Tableau for data analytics & visualization. You’ll learn what you need to work in the industry.",
    },
    {
      question: "Is the course available online?",
      answer:
        "Yes, the Data Science 360 Course is an Online program available only in interactive mode. All our live sessions are fully Interactive where you can interact with Trainer & fellow learners.",
    },
    {
      question: "How is the course content delivered?",
      answer:
        "The course includes 250+ hours of live online classes conducted by industry experts in an interactive mode to enhance the learning experience of a learner And 100+ hours of Self Paced classes ",
    },
    {
      question: "When are the live classes scheduled?",
      answer:
        "Live classes are held on weekdays and weekends.Sat - Sun - 10:00 AM to 01:00 PM (IST). Mon - Fri - 08:00 PM to 09:30 PM (IST).",
    },
    {
      question: "What is the duration of this course?",
      answer:
        "The standard duration is 6 months, but you can complete it faster with the self-paced Material.",
    },
    {
      question: "What kind of support is provided to students?",
      answer:
        "Students receive daily doubt sessions available from 10:00 AM to 10:00 PM (IST), dedicated study materials, assignments for each module, industry-similar case studies, and capstone projects. After course completion you’ll get Resume building, General Aptitude Test Preparation & Industry Mentor sessions to secure a rewarding job.",
    },
    {
      question: "Can I pay the fee in installments?",
      answer:
        "Yes, there are  part payment options available for this program. The course is already offered at a bottom price. But still you can avail Credit Card EMIs, Debit Card EMIs and other options offered by our payment aggregator",
    },
    {
      question: "What is the process of enrollment?",
      answer:
        "To enroll, you need to share your name, phone number, email, sex, and state/country. You will receive an email from Brainalyst containing your user ID and password for the LMS. After logging in with the given credentials, you will see the course on your dashboard and can make the payment to access the course. The enrollment team will guide you through the process.",
    },
    {
      question: "What kind of job support is provided?",
      answer:
        "The course offers 100% job support with hiring partners, including resume building, mock interviews, and general aptitude preparation sessions.",
    },

  ];

  return (
    <section className="faq" id="faq" style={{marginTop: '1rem'}}>
      <div className="container">
      <div style={styles.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1 style={{fontFamily: 'margaine sans-serif',fontWeight: '900',fontStyle: 'normal'}}>Data Science 360 Program <span style={{color: 'rgb(231 121 55)',fontWeight: '700'}}> FAQs ?</span></h1>{" "}
          
        </div>
        <hr />
        {faqData.map((item, index) => (
          <FAQItem key={index} question={item.question} answer={item.answer} />
        ))}
      </div></div>
    </section>
  );
};
const styles = {
  container: {
    width: "100%",
    margin: "auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
  },
  question: {
    backgroundColor: "#f1f1f1",
    cursor: "pointer",
    padding: "15px",
    width: "100%",
    border: "none",
    textAlign: "left",
    outline: "none",
    fontSize: "15px",
    marginBottom: "10px",
  },
  answer: {
    padding: "18px",
    //   backgroundColor: '#f9f9f9',
    border: "1px solid #ddd",
    marginBottom: "10px",
  },
};
export default FAQ;